import { useModal } from 'vue-final-modal'
import AmendmentCreateModal from '@register/components/Amendment/Create/AmendmentCreateModal.vue'
import type { ILeaseVariationDocument } from '@register'

export const useAmendmentFormModal = (
  amendment: ILeaseVariationDocument,
  startDate?: string,
) => {
  const { open, close } = useModal({
    component: AmendmentCreateModal,
    attrs: {
      amendment,
      startDate,
      onConfirm: () => {
        close()
      },
      onClose: () => {
        close()
      },
    },
  })

  return open
}
